<template>
    <v-hover v-slot="{ hover }">
        <v-card
            :elevation="hover ? 8 : 2"
            class="ma-2"
            @click="goToProductPage(product.name, product.idx, product.family)"
        >
            <!-- CARD TITLE -->
            <p class="centuryGothicBold productCardTitle pt-3">{{ product.name }}</p>

            <!-- PRODUCT IMAGE -->
            <img
                :src="require('./../../assets/products/' + product.img)"
                alt=""
                class="productCardImage"
            >

            <!-- GO TO PRODUCT BUTTON -->
            <v-row justify="end">
                <v-btn
                    class="centuryGothic my-3 mr-3 knowMoreButton"
                    elevation="0"
                    plain
                >
                    Conoce Más
                    <v-icon
                        small
                    >mdi-arrow-right
                    </v-icon>
                </v-btn>
            </v-row>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "Product_Card",
    props: [
        'product'
    ],
    methods: {
        goToProductPage(productName, id, family) {
            let parsedName = productName.replace(/\s+/g, '_').toLowerCase();
            let idx = '=' + id + '&' + family
            this.$router.push("/product/" + parsedName + idx);
        }
    }
}
</script>

<style scoped>
.productCardTitle {
    text-align: center !important;
    font-size: 20px;
}

.productCardImage {
    width: 100%;
    border-top: 1px solid #231F20;
    border-bottom: 1px solid #231F20;
}
</style>